import React from "react";
import {
  Box,
  TextField,
  Stack,
  Grid,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import logo from "../images/background.jpg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Resto from "../images/3.png";
import { userSchema } from "../validations/UserValidation";
import { useMutation } from "react-query";
import { request } from "../Request/request";

const ADMIN_URL = "https://admin-v2.restu-plus.com/check";
const KITCHEN_URL = "https://kitchen-v2.restu-plus.com/check";
const WAITER_URL = "https://waiter-v2.restu-plus.com/check";
const CASHIER_URL = "https://cashier-v2.restu-plus.com/check";

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const loginQuery = useMutation({
    mutationKey: ["login"],
    mutationFn: (data) => {
      return request({
        url: "/login",
        method: "POST",
        data,
      });
    },
    onSuccess: (res) => {
      console.log(res.data.user.user_type);
      if (res.data.user.user_type == 4 || res.data.user.user_type == 5)
        window.location.href = `${ADMIN_URL}/${res.data.UUID}`;
      if (res.data.user.user_type == 2)
        window.location.href = `${KITCHEN_URL}/${res.data.UUID}`;
      if (res.data.user.user_type == 1)
        window.location.href = `${WAITER_URL}/${res.data.UUID}`;
      if (res.data.user.user_type == 3)
        window.location.href = `${CASHIER_URL}/${res.data.UUID}`;
    },
    onError: (res) => {},
  });

  const submitHandler = (values) => {
    loginQuery.mutate(values);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Box padding={"1rem"} width={"200px"}>
            <img
              src={Resto}
              style={{
                maxWidth: "100%",
              }}
            />
          </Box>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={userSchema}
            onSubmit={submitHandler}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form
                style={{
                  padding: "1rem",
                }}
                onSubmit={handleSubmit}
              >
                <Stack mt={"4rem"} spacing={3}>
                  <Box>
                    <TextField
                      label="Email"
                      type="string"
                      variant="outlined"
                      onBlur={handleBlur}
                      name="email"
                      onChange={handleChange}
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      name="password"
                      autoComplete="current-password"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      fullWidth
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </Box>
                </Stack>
                <Box mt="3rem">
                  <LoadingButton
                    type={"submit"}
                    size="small"
                    loading={loginQuery.isLoading}
                    variant="contained"
                    fullWidth
                    sx={{
                      padding: "1rem",
                      borderRadius: "5px",
                      background:
                        "linear-gradient(to bottom, #dd78ef, #779bc2) !important",
                    }}
                  >
                    <span
                      style={{
                        // color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Log IN
                    </span>
                  </LoadingButton>
                </Box>
                {loginQuery.isError && (
                  <Box
                    sx={{ color: "red", fontWeight: "bold", margin: "1rem 0" }}
                  >
                    {loginQuery?.error?.response?.status
                      ? " Wrong Credential"
                      : "Network Error"}
                  </Box>
                )}
              </form>
            )}
          </Formik>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              position: "relative",
              backgroundImage: `url("${logo}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgoundPosition: "center",
              minHeight: "100vh",
              display: {
                md: "block",
                sm: "none",
                xs: "none",
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                top: "40%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <img
                src={Resto}
                style={{
                  maxWidth: "100%",
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
